import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Sending login request:', { email, password });

    try {
      const response = await axios.post(
        'https://bizznizz.eu/api/login',
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      );

      console.log('Login response:', response.data);
      localStorage.setItem('token', response.data.access_token);
      navigate('/feedback');
    } catch (err) {
      console.error('Error logging in:', err.response?.data || err.message);
      setError('Invalid email or password');
    }
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-b from-gray-800 via-gray-700 to-gray-900 text-white overflow-y-hidden">
      <nav className="bg-gray-800 text-white p-4 flex justify-between items-center w-full shadow-lg fixed top-0 z-50">
        <div className="flex items-center">
          <img src="./logo.png" alt="Logo" className="h-10 w-10 mr-2" />
          <h1 className="text-2xl font-extrabold tracking-wide text-[#68A9FF]">
            BizzNizz AI Builder
          </h1>
        </div>
        <ul className="flex items-center space-x-6">
          <li>
            <Link to="/" className="hover:text-[#68A9FF] transition font-montserrat font-bold text-white">
              Home
            </Link>
          </li>
        </ul>
      </nav>

      <div className="flex flex-col justify-center items-center flex-grow mt-32 mb-12 p-8">
        <div className="bg-gray-800 p-10 rounded-lg shadow-xl max-w-md w-full">
          <h2 className="text-4xl font-extrabold mb-6 text-center text-white">Login</h2>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="px-4 py-3 rounded-md bg-gray-700 text-white"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="px-4 py-3 rounded-md bg-gray-700 text-white"
            />
            <button
              type="submit"
              className="mt-6 px-6 py-3 bg-[#4088FF] text-white text-lg font-semibold rounded-full shadow-lg hover:bg-[#68A9FF] transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 ring-[#4088FF] ring-opacity-50"            >
              Login
            </button>
          </form>
          <div className="flex flex-col items-center mt-6">
            <button
              onClick={() => navigate('/register')}
              className="mt-4 py-2 text-white rounded-full"
            >
              Register
            </button>
          </div>
        </div>
      </div>

      <footer className="bg-gray-900 py-6 flex justify-center items-center w-full text-gray-400">
        <img src="/logo.png" alt="CrossConnected Logo" className="h-10 mr-2" />
        <p className="text-lg">© 2024 CrossConnected.eu</p>
      </footer>
    </div>
  );
};

export default LoginComponent;
