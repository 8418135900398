import React from 'react';

export const PropertyHandling = ({
  existingClassName,
  setExistingClassName,
  schema,
  newProperties,
  handlePropertyChange,
  setNewProperties,
  handleAddPropertyToClass,
  addPropertiesToClass,
  selectedProperty,
  setSelectedProperty,
  handleRemoveProperty,
  setStep,
}) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
    {/* Back Button */}
    <button
  onClick={() => {
    setStep(12);
  }}
  className="text-blue-400 mb-4 hover:underline"
>
  Back
</button>


    <label className="block mb-2">Select a Class to Handle Properties:</label>
    <select
      value={existingClassName}
      onChange={(e) => setExistingClassName(e.target.value)}
      className="mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
    >
      <option value="">Select a class...</option>
      {schema?.classes.map((cls, index) => (
        <option key={index} value={cls.class_name}>{cls.class_name}</option>
      ))}
    </select>

    {/* New Properties List with Remove Option */}
    {newProperties.map((property, index) => (
      <div key={index} className="flex items-center mb-2">
        <input
          type="text"
          placeholder="Property name"
          value={property.property}
          onChange={(e) => handlePropertyChange(index, 'property', e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md flex-grow"
        />
        <input
          type="text"
          placeholder="Data type (e.g., CHAR(26))"
          value={property.data_type}
          onChange={(e) => handlePropertyChange(index, 'data_type', e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md flex-grow"
        />
        <button
          onClick={() => setNewProperties(newProperties.filter((_, i) => i !== index))}
          className="ml-2 text-red-500 hover:underline"
        >
          Remove
        </button>
      </div>
    ))}

    <button onClick={handleAddPropertyToClass} className="text-blue-500">+ Add Property</button>

    <button
      onClick={addPropertiesToClass}
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mt-4"
    >
      Handle Properties
    </button>

    {/* Remove Selected Property Option */}
    {existingClassName && (
      <>
        <label className="block mt-4 mb-2">Remove a Property from {existingClassName}:</label>
        <select
          value={selectedProperty}
          onChange={(e) => setSelectedProperty(e.target.value)}
          className="mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select a property...</option>
          {schema?.classes.find(cls => cls.class_name === existingClassName)?.properties.map((prop, index) => (
            <option key={index} value={prop.property}>{prop.property}</option>
          ))}
        </select>
        <button
          onClick={() => handleRemoveProperty(existingClassName, selectedProperty)}
          className="text-red-500 hover:underline"
        >
          Remove Selected Property
        </button>
      </>
    )}
  </div>
);
