import React, { useState } from 'react';

export const ApplyManualRelationship = ({
  relationshipInput,
  handleInputChange,
  sourceSuggestions = [],
  targetSuggestions = [],
  setRelationshipInput,
  setSourceSuggestions,
  setIsSelectingTarget,
  setTargetSuggestions,
  handleApplyManualRelationship,
  proceedToNextStep
}) => {
  const [isApplying, setIsApplying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleApplyClick = async () => {
    if (!relationshipInput.includes(',') || relationshipInput.split(',').length !== 2) {
      setErrorMessage('Please provide both source and target classes separated by a comma.');
      return;
    }
  
    const [sourceClass, targetClass] = relationshipInput.split(',').map((cls) => cls.trim());
    if (!sourceClass || !targetClass) {
      setErrorMessage('Source and target classes cannot be empty.');
      return;
    }
  
    setIsApplying(true);
    setErrorMessage('');
  
    try {
      await handleApplyManualRelationship(relationshipInput);
      // Reset inputs on success
      setRelationshipInput('');
      setSourceSuggestions([]);
      setTargetSuggestions([]);
      setIsSelectingTarget(false);
    } catch (error) {
      console.error('Error applying relationship:', error);
      setErrorMessage('Failed to apply relationship. Please try again.');
    } finally {
      setIsApplying(false);
    }
  };
  

  return (
    <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
      <label className="block mb-2">Enter the source and target classes:</label>

      <input
        type="text"
        placeholder="Enter source and target class (e.g., Employee,Client)"
        className="w-full mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        value={relationshipInput}
        onChange={(e) => handleInputChange(e.target.value)}
      />

      {/* Error Message Display */}
      {errorMessage && (
        <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>
      )}

      {!relationshipInput.includes(',') && sourceSuggestions.length > 0 && (
        <ul className="bg-gray-700 border border-gray-600 rounded-md mb-4 max-h-40 overflow-y-auto">
          {sourceSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-600"
              onClick={() => {
                setRelationshipInput(
                  relationshipInput.includes(',')
                    ? relationshipInput.split(',')[0].trim() + ',' + suggestion.class_name
                    : suggestion.class_name + ', '
                );
                setSourceSuggestions([]);
                setTargetSuggestions([]);
                setIsSelectingTarget(false);
              }}
              
            >
              {suggestion.class_name}
            </li>
          ))}
        </ul>
      )}

      {relationshipInput.includes(',') && targetSuggestions.length > 0 && (
        <ul className="bg-gray-700 border border-gray-600 rounded-md mb-4 max-h-40 overflow-y-auto">
          {targetSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-600"
              onClick={() => {
                setRelationshipInput(relationshipInput + suggestion.class_name);
                setTargetSuggestions([]);
              }}
            >
              {suggestion.class_name}
            </li>
          ))}
        </ul>
      )}

      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
        <button
          className={`w-full md:w-auto p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg hover:scale-102 ${
            isApplying ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleApplyClick}
          disabled={isApplying}
        >
          {isApplying ? 'Applying...' : 'Apply Relationship'}
        </button>

        <button
          className="w-full md:w-auto p-2 rounded-full transition bg-gray-600 text-white hover:bg-gray-500 shadow-lg hover:scale-102"
          onClick={() => {
            setRelationshipInput('');
            proceedToNextStep();
          }}
        >
          Done Adding Relationships
        </button>
      </div>
    </div>
  );
};
