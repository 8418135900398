
export const FeedbackDashboard = ({
  allFeedback,
  handleFeedbackAction,
  goToSchemaEditor,
  setStep,
  schema, // Pass schema as a prop
  businessType, // Pass business type as a prop
  description, // Pass description as a prop
}) => (
  <div className="p-4 bg-gray-800 shadow-lg rounded-t-lg h-full flex flex-col">
    <h2 className="text-xl mb-4 text-white text-center">Feedback Dashboard</h2>

    {/* Feedback Categories */}
    <div className="flex-1 overflow-y-auto max-h-full">
      {Object.keys(allFeedback).map((category, index) => (
        <div key={index} className="mb-6 border-b border-gray-600 pb-4">
          <h3 className="text-lg font-bold text-[#68A9FF] capitalize mb-2">
            {category}
          </h3>
          {allFeedback[category].length ? (
            <ul className="space-y-4">
              {allFeedback[category].map((feedback, i) => (
                <li
                  key={i}
                  className="p-4 bg-gray-700 rounded-lg shadow-md"
                >
                  <p className="text-sm text-[#68A9FF] font-bold mb-2">
                    {feedback.action || 'Action not specified'}
                  </p>
                  <p className="text-white text-sm">
                    {feedback.explanation || 'No explanation provided.'}
                  </p>
                  <button
                    className="mt-2 px-3 py-1 bg-green-600 hover:bg-green-700 text-white rounded-lg text-sm font-semibold"
                    onClick={() => handleFeedbackAction(category, i)}
                  >
                    Address
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-400 text-sm">
              - No issues detected for the {category.toLowerCase()} category.
            </p>
          )}
        </div>
      ))}
    </div>

    {/* Footer Buttons */}
    <div className="flex justify-between mt-4">
      <button
        className="w-full mr-2 p-2 bg-blue-600 text-white hover:bg-blue-700 rounded-full text-lg"
        onClick={goToSchemaEditor}
      >
        Go to Schema Editor
      </button>
      <button
        className="w-full ml-2 p-2 bg-blue-600 text-white hover:bg-blue-700 rounded-full text-lg"
        onClick={() => setStep(12)} // Navigate to Schema Review
      >
        Review Schema
      </button>
    </div>
  </div>
);
