import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

const RegisterComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
  
    // Check if passwords match
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
  
    const payload = { email, password };
    console.log("Sending payload:", payload); // Log the payload being sent
  
    try {
      const response = await axios.post(
        'https://bizznizz.eu/api/register',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );
  
      console.log("Response received:", response);
  
      if (response.status === 200) {
        setMessage('Registration successful! Please wait for admin approval.');
        navigate('/login');
      } else {
        setMessage(response.data.detail || 'Registration failed');
      }
    } catch (err) {
      console.error("Error registering user:", err);
      setMessage(err.response?.data?.detail || 'Error registering user');
    }
  };

return (
<div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-800 via-gray-700 to-gray-900 text-white">
  {/* Navbar */}
  <nav className="bg-gray-800 text-white p-4 flex justify-between items-center w-full shadow-lg fixed top-0 z-50">
    <div className="flex items-center">
      <img src="./logo.png" alt="Logo" className="h-10 w-10 mr-2" />
      <h1 className="text-2xl font-extrabold tracking-wide text-[#68A9FF]">
        BizzNizz AI Builder
      </h1>
    </div>
    <ul className="flex items-center space-x-6">
      <li>
        <Link to="/" className="hover:text-[#68A9FF] transition font-montserrat font-bold text-white">
          Home
        </Link>
      </li>
    </ul>
  </nav>

  {/* Main content */}
  <main className="flex-grow flex flex-col justify-center items-center">
    <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-md w-full mt-20">
      <h2 className="text-4xl font-bold mb-6 text-center">Register</h2>
      {message && <p className="text-red-500 text-center mb-4">{message}</p>}
      <form onSubmit={handleRegister} className="flex flex-col space-y-4">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="px-4 py-3 rounded bg-gray-700"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="px-4 py-3 rounded bg-gray-700"
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          className="px-4 py-3 rounded bg-gray-700"
        />
        <button
          type="submit"
          className="mt-6 px-10 py-4 bg-[#4088FF] text-white text-lg font-semibold rounded-full shadow-lg hover:bg-[#68A9FF] transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 ring-[#4088FF] ring-opacity-50"
        >
          Register
        </button>
      </form>
      <div className="mt-6 text-center">
        <Link to="/login" className="text-white">Back to Login</Link>
      </div>
    </div>
  </main>

  {/* Footer */}
  <footer className="bg-gray-900 py-6 flex justify-center items-center w-full text-gray-400">
        <img src="/logo.png" alt="CrossConnected Logo" className="h-10 mr-2" />
        <p className="text-lg">© 2024 CrossConnected.eu</p>
      </footer>
</div>
  );
};

export default RegisterComponent;
