import axios from 'axios';

const api = axios.create({
  baseURL: 'https://bizznizz.eu/api',
});

/* -------------------- Fetch Suggested Data -------------------- */

export const fetchSuggestedClassesApi = async (businessType) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/suggest-classes/', {
      business_type: businessType,
    });
    return response.data.suggested_classes;
  } catch (error) {
    console.error("Error fetching suggested classes:", error);
    throw new Error('Error fetching suggested classes.');
  }
};

export const fetchSuggestedPropertiesApi = async (className, schema_data, businessType) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/suggest-properties/', {
      class_name: className,
      schema_data,
      business_type: businessType,
    });
    return response.data.suggested_properties;
  } catch (error) {
    console.error("Error fetching suggested properties:", error);
    throw new Error('Error fetching suggested properties.');
  }
};

/* -------------------- Add Data to schema_data -------------------- */

export const addSuggestedClassApi = async (className, schema_data, suggestedProperties) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/add-class/', {
      schema_data,
      class_name: className,
      properties: suggestedProperties,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error adding suggested class:", error);
    throw new Error('Error adding suggested class.');
  }
};

export const addClassToSchemaApi = async (schema_data, className, properties) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/add-class/', {
      schema_data,
      class_name: className,
      properties,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error adding class to schema:", error);
    throw error;
  }
};

export const addPropertiesToClassApi = async (schema_data, className, properties) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/add-property/', {
      schema_data,
      class_name: className,
      properties,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error adding properties to class:", error);
    throw error;
  }
};

/* -------------------- Generate Schema -------------------- */

export const generateSchemaApi = async (businessType, description) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/generate-schema/', {
      business_type: businessType,
      description,
    });
    return response.data.schema;
  } catch (error) {
    console.error("Error generating schema:", error);
    throw new Error('Error generating schema.');
  }
};

/* -------------------- Fetch Feedback -------------------- */

export const getFeedbackApi = async (schema_data, category, businessType) => {
  try {
    const response = await api.post('/get-feedback/', {
      schema_data,
      category: category,
      business_type: businessType,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching feedback:", error.response?.data || error.message);
    throw new Error(`Error fetching feedback for ${category}.`);
  }
};


/* -------------------- Apply Feedback Actions -------------------- */

export const renameEntityApi = async (schema_data, className, propertyName, newName) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/rename-entity', {
      schema_data,
      class_name: className,
      property_name: propertyName,
      new_name: newName,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error renaming entity:", error);
    throw error;
  }
};

export const setTypeApi = async (schema_data, className, propertyName, newType) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/set-type', {
      schema_data,
      class_name: className,
      property_name: propertyName,
      new_type: newType,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error setting type:", error);
    throw error;
  }
};

export const addConstraintApi = async (schema_data, className, propertyName, constraintType) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/add-constraint', {
      schema_data,
      class_name: className,
      property_name: propertyName,
      constraint_type: constraintType,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error adding constraint:", error);
    throw error;
  }
};

export const removeConstraintApi = async (schema_data, className, propertyName, constraintType) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/remove-constraint', {
      schema_data,
      class_name: className,
      property_name: propertyName,
      constraint_type: constraintType,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error removing constraint:", error);
    throw error;
  }
};

export const applyRelationshipApi = async (schema_data, sourceClass, targetClass) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/apply-feedback/', {
      schema_data,
      feedback_category: 'Relationships',
      feedback: { relationships: [{ source_class: sourceClass, target_class: targetClass }] },
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error applying relationship:", error);
    throw error;
  }
};



export const applyRedundancyFeedbackApi = async (schema_data, feedbackObject, newClassName) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/apply-feedback/', {
      schema_data,
      feedback_category: 'Redundancy',
      feedback: feedbackObject, // Ensure this is structured correctly
      new_class_name: newClassName,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error applying redundancy feedback:", error.response?.data || error.message);
    throw error;
  }
};


export const applyNormalizationApi = async (schema_data, feedbackItem, newPropertiesArray, dataTypesArray) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/apply-feedback/', {
      schema_data,
      feedback_category: 'Normalization',
      feedback: feedbackItem,
      new_properties: newPropertiesArray,
      data_types: dataTypesArray,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error applying normalization:", error);
    throw error;
  }
};

/* -------------------- Remove Data from Schema -------------------- */

export const removeClassApi = async (schema_data, className) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/remove-class/', {
      schema_data,
      class_name: className,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error removing class:", error);
    throw error;
  }
};

export const removePropertyApi = async (schema_data, className, propertyName) => {
  try {
    const response = await axios.post('https://bizznizz.eu/api/remove-property/', {
      schema_data,
      class_name: className,
      property_name: propertyName,
    });
    return response.data.updated_schema;
  } catch (error) {
    console.error("Error removing property:", error);
    throw error;
  }
};
