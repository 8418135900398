import React from 'react';

export const RelationshipConfirmation = ({ userResponse, setUserResponse, handleUserFeedbackResponse }) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
    <label className="block mb-2">Would you like to apply the relationships feedback? (yes/no)</label>
    <input
      type="text"
      placeholder="Type yes or no"
      className="w-full mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
      value={userResponse}
      onChange={(e) => setUserResponse(e.target.value)}
    />
    <button
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg hover:scale-102"
      onClick={() => handleUserFeedbackResponse(userResponse)}
    >
      Submit Response
    </button>
  </div>
);
