import React, { useState } from 'react';

export const JoinTableChildPrompt = ({ joinTable, properties, handleChildSelection }) => {
  const [selectedProperties, setSelectedProperties] = useState([]);

  const toggleProperty = (property) => {
    setSelectedProperties(prev => 
      prev.includes(property) ? prev.filter(p => p !== property) : [...prev, property]
    );
  };

  const handleSubmit = () => {
    handleChildSelection(joinTable, selectedProperties);
  };

  return (
    <div className="p-4 shadow-md rounded-md bg-gray-700 text-white">
      <h3 className="text-lg mb-4">Configure Child Properties for {joinTable}</h3>
      <p>Select properties that should create child objects:</p>
      {properties.map((property, index) => (
        <label key={index} className="block my-2">
          <input
            type="checkbox"
            checked={selectedProperties.includes(property)}
            onChange={() => toggleProperty(property)}
            className="mr-2"
          />
          {property}
        </label>
      ))}
      <button
        className="mt-4 p-2 bg-blue-500 rounded-full"
        onClick={handleSubmit}
      >
        Confirm Selection
      </button>
    </div>
  );
};
