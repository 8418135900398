import React from 'react';
import { Link } from 'react-router-dom';

const exampleSchema = {
  classes: [
    {
      class_name: "User",
      properties: [
        {
          property: "ULID",
          data_type: "CHAR(26)",
          primary_key: true,
          not_null: true,
          foreign_key: false,
          references: ""
        }
      ]
    },
    {
      class_name: "Seller",
      properties: [
        {
          property: "ULID",
          data_type: "CHAR(26)",
          primary_key: true,
          not_null: true,
          foreign_key: false,
          references: ""
        },
        {
          property: "user_id",
          data_type: "CHAR(26)",
          foreign_key: true,
          not_null: true,
          references: "User(ULID)",
          primary_key: false
        }
      ]
    },
    {
      class_name: "Product",
      properties: [
        {
          property: "ULID",
          data_type: "CHAR(26)",
          primary_key: true,
          not_null: true,
          foreign_key: false,
          references: ""
        },
        {
          property: "seller_id",
          data_type: "CHAR(26)",
          foreign_key: true,
          not_null: true,
          references: "Seller(ULID)",
          primary_key: false
        }
      ]
    },
    {
      class_name: "Order",
      properties: [
        {
          property: "ULID",
          data_type: "CHAR(26)",
          primary_key: true,
          not_null: true,
          foreign_key: false,
          references: ""
        },
        {
          property: "user_id",
          data_type: "CHAR(26)",
          foreign_key: true,
          not_null: true,
          references: "User(ULID)",
          primary_key: false
        }
      ]
    },
    {
      class_name: "CartItem",
      properties: [
        {
          property: "product_id",
          data_type: "CHAR(26)",
          foreign_key: true,
          not_null: true,
          references: "Product(ULID)",
          primary_key: false
        },
        {
          property: "order_id",
          data_type: "CHAR(26)",
          foreign_key: true,
          not_null: true,
          references: "Order(ULID)",
          primary_key: false
        },
        {
          property: "quantity",
          data_type: "INT",
          not_null: true,
          primary_key: false,
          foreign_key: false,
          references: ""
        }
      ]
    },
    {
      class_name: "Payment",
      properties: [
        {
          property: "ULID",
          data_type: "CHAR(26)",
          primary_key: true,
          not_null: true,
          foreign_key: false,
          references: ""
        },
        {
          property: "order_id",
          data_type: "CHAR(26)",
          foreign_key: true,
          not_null: true,
          references: "Order(ULID)",
          primary_key: false
        },
        {
          property: "payment_method",
          data_type: "VARCHAR(50)",
          not_null: true,
          primary_key: false,
          foreign_key: false,
          references: ""
        }
      ]
    }
  ]
};

const IndexComponent = () => {
  const steps = [
    {
      img: "Text.png",
      alt: "Describe",
      description: "Describe your business structure",
    },
    {
      img: "Artificial Intelligence.png",
      alt: "AI Feedback",
      description: "Let AI give you feedback",
    },
    {
      img: "Edit.png",
      alt: "Make Changes",
      description: "Make changes where necessary",
    },
    {
      img: "Stacked Organizational Chart.png",
      alt: "Get Backend",
      description: "Get your entire back-end structure in one go",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white overflow-y-hidden">
      {/* Navbar */}
      <nav className="bg-gray-800 text-white p-4 flex justify-between items-center w-full shadow-lg z-50">
        <div className="flex items-center">
          <img src="./logo.png" alt="Logo" className="h-10 w-10 mr-2" />
          <h1 className="text-2xl font-extrabold tracking-wide text-[#68A9FF] drop-shadow-lg">
            BizzNizz AI Builder
          </h1>
        </div>
        <ul className="flex items-center space-x-6">
          <li>
            <Link to="/" className="hover:text-[#68A9FF] transition font-montserrat font-bold text-white">
              Home
            </Link>
          </li>
        </ul>
      </nav>

      {/* Main content */}
      <div className="flex-grow flex flex-col justify-center items-center bg-gradient-to-b from-gray-800 via-gray-700 to-gray-900 text-center p-8">
        <h2 className="text-5xl font-extrabold text-white mb-4 font-montserrat drop-shadow-lg tracking-wider">
          BizzNizz AI Builder
        </h2>
        <p className="text-gray-300 text-lg mb-8 max-w-lg font-light leading-relaxed font-montserrat">
          Generate your entire business structure in a few easy steps with the help of AI.
        </p>
        <Link to={localStorage.getItem('token') ? "/feedback" : "/login"}>
          <button className="mt-6 px-10 py-4 bg-[#4088FF] text-white text-lg font-semibold rounded-full shadow-lg hover:bg-[#68A9FF] transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 ring-[#4088FF] ring-opacity-50">
            Start Building
          </button>
        </Link>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 py-6 flex justify-center items-center w-full text-gray-400">
        <img src="/logo.png" alt="CrossConnected Logo" className="h-10 mr-2" />
        <p className="text-lg">© 2024 CrossConnected.eu</p>
      </footer>
    </div>
  );
};

export default IndexComponent;
