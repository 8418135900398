import React, { useState } from 'react';

export const RelationshipFeedbackPrompt = ({
  handleApplyManualRelationship,
  moveToNextCategory,
}) => {
  const [relationshipInput, setRelationshipInput] = useState('');

  return (
    <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
      <label className="block mb-2">
        Provide the source and target classes for the relationship (e.g., `source_class,target_class`)
      </label>
      <input
        type="text"
        placeholder="e.g., Employee,Department"
        className="w-full mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        value={relationshipInput}
        onChange={(e) => setRelationshipInput(e.target.value)}
      />
      <button
        className="w-full mt-4 p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg hover:scale-102"
        onClick={() => handleApplyManualRelationship(relationshipInput)}
      >
        Apply Relationship
      </button>
      <button
        className="w-full mt-4 p-2 rounded-full transition bg-green-600 text-white hover:bg-green-700 shadow-lg hover:scale-102"
        onClick={moveToNextCategory}
      >
        Done Applying Relationship
      </button>
    </div>
  );
};
