import React from 'react';

export const ClassHandling = ({
  additionalClassName,
  setAdditionalClassName,
  fetchSuggestedClasses,
  suggestedClasses,
  handleSelectSuggestedClass,
  suggestedProperties,
  addSuggestedProperty,
  newClassProperties,
  handleClassPropertyChange,
  setNewClassProperties,
  handleAddClassProperty,
  addClassToSchema,
  schema,
  handleRemoveClass,
  setStep,
}) => (
  <div className="p-4 shadow-md rounded-md flex flex-col bg-gray-700 text-white">
    {/* Back Button */}
    <button
  onClick={() => {
    setStep(12);
    setAdditionalClassName('');
  }}
  className="text-blue-400 mb-4 hover:underline"
>
  Back
</button>

    <label className="mb-2">Enter New Class Name:</label>
    <input
      type="text"
      placeholder="New class name"
      className="w-full mb-4 p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
      value={additionalClassName}
      onChange={(e) => setAdditionalClassName(e.target.value)}
    />

    {/* Fetch AI-Suggested Classes */}
    <button onClick={fetchSuggestedClasses} className="text-blue-500 mb-4 hover:underline">
      Get AI-Suggested Classes
    </button>

    {/* Suggested Classes List */}
    {suggestedClasses.length > 0 && (
      <div className="mb-4">
        <label className="block mb-2">Suggested Classes:</label>
        {suggestedClasses.map((suggestedClass, index) => (
          <div
            key={index}
            className="cursor-pointer text-blue-400 mb-2 hover:underline"
            onClick={() => handleSelectSuggestedClass(suggestedClass.trim())}
          >
            {suggestedClass}
          </div>
        ))}
      </div>
    )}

    {/* Suggested Properties Display */}
    {suggestedProperties.map((prop, index) => (
      <div
        key={index}
        className="cursor-pointer text-blue-400 mb-2 hover:underline"
        onClick={() => addSuggestedProperty(prop)}
      >
        {`${prop.property}: ${prop.data_type}`}
      </div>
    ))}

    {/* New Properties List with Remove Option */}
    {newClassProperties.map((property, index) => (
      <div key={index} className="flex items-center mb-2">
        <input
          type="text"
          placeholder="Property name"
          value={property.property}
          onChange={(e) => handleClassPropertyChange(index, 'property', e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md flex-grow"
        />
        <input
          type="text"
          placeholder="Data type (e.g., CHAR(26))"
          value={property.data_type}
          onChange={(e) => handleClassPropertyChange(index, 'data_type', e.target.value)}
          className="mb-2 p-2 border bg-gray-600 border-gray-500 text-white rounded-md flex-grow"
        />
        <button
          onClick={() => setNewClassProperties(newClassProperties.filter((_, i) => i !== index))}
          className="ml-2 text-red-500 hover:underline"
        >
          Remove
        </button>
      </div>
    ))}

    <button onClick={handleAddClassProperty} className="text-blue-500">+ Add Property</button>

    <button
      onClick={addClassToSchema}
      className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mt-4"
    >
      Handle Classes
    </button>

    {/* Dropdown to Select a Class for Removal */}
    <div className="mt-4">
      <label className="block mb-2 text-sm font-medium text-gray-300">Select a Class to Remove:</label>
      <div className="flex items-center space-x-2">
        <select
          value={additionalClassName}
          onChange={(e) => setAdditionalClassName(e.target.value)}
          className="w-full p-2 border bg-gray-600 border-gray-500 text-white rounded-md"
        >
          <option value="">Select a class...</option>
          {schema?.classes.map((cls, index) => (
            <option key={index} value={cls.class_name}>{cls.class_name}</option>
          ))}
        </select>
        {additionalClassName && (
          <button
            onClick={() => handleRemoveClass(additionalClassName)}
            className="px-3 py-2 text-sm font-semibold text-red-500 hover:underline hover:text-red-400 hover:bg-gray-700 rounded-md transition"
          >
            Remove Selected Class
          </button>
        )}
      </div>
    </div>
  </div>
);
