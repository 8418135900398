import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import businessTypesData from './business_types.json';
import { ToastContainer, Toast } from 'react-bootstrap';
import {
  fetchSuggestedClassesApi,
  fetchSuggestedPropertiesApi,
  addSuggestedClassApi,
  generateSchemaApi,
  getFeedbackApi,
  renameEntityApi,
  setTypeApi,
  addConstraintApi,
  removeConstraintApi,
  applyRelationshipApi,
  applyRedundancyFeedbackApi,
  applyNormalizationApi,
  removeClassApi,
  removePropertyApi,
  addClassToSchemaApi,
  addPropertiesToClassApi
} from '../api/api';

import { BusinessTypeAndDescription } from './steps/BusinessTypeAndDescription';
import { UserFeedbackPrompt } from './steps/UserFeedbackPrompt';
import { ClassMerge } from './steps/ClassMerge';
import { RelationshipFeedbackPrompt } from './steps/RelationshipFeedbackPrompt';
import { ApplyManualRelationship } from './steps/ApplyManualRelationship';
import { RelationshipConfirmation } from './steps/RelationshipConfirmation';
import { ClassHandling } from './steps/ClassHandling';
import { PropertyHandling } from './steps/PropertyHandling';
import { SchemaReview } from './steps/SchemaReview';
import { CreateChildPrompt } from './steps/CreateChildPrompt';
import { DataIntegrityManagement } from './steps/DataIntegrityManagement';
import { JoinTableChildPrompt } from './steps/JoinTableChildPrompt';
import { FeedbackDashboard } from './steps/FeedbackDashboard';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import JSONPretty from 'react-json-pretty';
import '../App.css';

// Initial State and Effects
const ChatBot = () => {
  const [businessType, setBusinessType] = useState('');
  const [description, setDescription] = useState('');
  const [schema_data, setSchemaData] = useState(null);
  const [chatHistory, setChatHistory] = useState([
    { from: 'bot', message: 'Welcome to BizzNizz AI Builder.' },
    { from: 'bot', message: 'Please provide your business type and your business structure description.' },
  ]);
  const [step, setStep] = useState(1);
  const [feedback, setFeedback] = useState(null);
  const [newClassName, setNewClassName] = useState('');
  const [userResponse, setUserResponse] = useState('');
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [relationships, setRelationships] = useState([]);
  const [relationshipInput, setRelationshipInput] = useState('');
  const [sourceSuggestions, setSourceSuggestions] = useState([]);
  const [targetSuggestions, setTargetSuggestions] = useState([]);
  const [isSelectingTarget, setIsSelectingTarget] = useState(false);
  const [normalizationFeedback, setNormalizationFeedback] = useState([]);
  const [selectedNormalizationIndices, setSelectedNormalizationIndices] = useState([]);
  const [additionalClassName, setAdditionalClassName] = useState('');
  const [newClassProperties, setNewClassProperties] = useState([
    { property: '', data_type: '', primary_key: false, foreign_key: false, not_null: false, references: '' }
  ]);  
  const [targetClass, setTargetClass] = useState('');
  const [newProperties, setNewProperties] = useState([
    { property: '', data_type: '', primary_key: false, foreign_key: false, not_null: false, references: '' }
  ]);  
  const [suggestedClasses, setSuggestedClasses] = useState([]);
  const [suggestedProperties, setSuggestedProperties] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSuggestedProperty, setSelectedSuggestedProperty] = useState(null);
  const [existingClassName, setExistingClassName] = useState('');
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState([]);
  const [applyRelationshipsFeedback, setApplyRelationshipsFeedback] = useState(null);
  const [sourceClass, setSourceClass] = useState('');
  const [actionType, setActionType] = useState('');
  const [className, setClassName] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [newName, setNewName] = useState('');
  const [newType, setNewType] = useState('');
  const [constraintType, setConstraintType] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');
  const [classes, setClasses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const chatContainerRef = useRef(null);
  const categories = ['Redundancy', 'Relationships', 'Normalization', 'Data Integrity'];
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [createChildResponse, setCreateChildResponse] = useState('');
  const [createChildFlag, setCreateChildFlag] = useState(false);
  const [joinTablesToPrompt, setJoinTablesToPrompt] = useState([]);
  const [currentJoinTable, setCurrentJoinTable] = useState(null);
  const [joinTableProperties, setJoinTableProperties] = useState([]);
  const [joinTablePromptActive, setJoinTablePromptActive] = useState(false);
  const [isProcessingJoinTable, setIsProcessingJoinTable] = useState(false);
  const [isFeedbackTriggered, setIsFeedbackTriggered] = useState(false);
  const [allFeedback, setAllFeedback] = useState({
    redundancy: [],
    relationships: [],
    normalization: [],
    dataIntegrity: [],
  });
  const [hasRefinedDescription, setHasRefinedDescription] = useState(false);
  const [isSchemaUpdated, setIsSchemaUpdated] = useState(false);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  useEffect(() => {
    if (step === 3 && schema_data && isFeedbackTriggered) {
      handleFeedbackRequest();
    }
  }, [step, schema_data, categoryIndex, isFeedbackTriggered]);  

  useEffect(() => {
    if (step === 2 && schema_data && !isProcessingJoinTable) {
      handleFeedbackRequest();
    }
  }, [step, schema_data, categoryIndex, isProcessingJoinTable]);
  

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    if (schema_data) {
      detectJoinTables(schema_data);
    }
  }, [schema_data]);
  

  useEffect(() => {
    if (businessType) {
      const filtered = businessTypesData.filter(type =>
        type.business_type.toLowerCase().includes(businessType.toLowerCase())
      );
      setFilteredBusinessTypes(filtered);
    } else {
      setFilteredBusinessTypes([]);
    }
  }, [businessType]);

  useEffect(() => {
    if (schema_data) {
      setClasses(schema_data.classes.map(cls => cls.class_name));
    }
  }, [schema_data]);
  
  useEffect(() => {
    if (isSchemaUpdated) {
      regenerateAllFeedback();
      setIsSchemaUpdated(false); // Reset the update flag
    }
  }, [isSchemaUpdated]);
  
  const regenerateAllFeedback = async () => {
    try {
      const categories = ['Redundancy', 'Relationships', 'Normalization', 'Data Integrity'];
      const feedbackResults = {};
  
      for (const category of categories) {
        const feedbackData = await getFeedbackApi(schema_data, category, businessType);
        feedbackResults[category.toLowerCase()] = feedbackData.feedback || [];
      }
  
      setAllFeedback(feedbackResults);
      chatbotResponse('Feedback has been regenerated due to schema updates.');
    } catch (error) {
      chatbotResponse('Error regenerating feedback.');
    }
  };
  
  const updateSchemaData = (newSchema) => {
    console.log('Updating schema data...');
    console.log('Current Schema:', schema_data);
    console.log('New Schema:', newSchema);
    
    setUndoHistory((prev) => {
      console.log('Undo History Before:', prev);
      return [...prev, schema_data];
    });
  
    console.log('Undo History After:', undoHistory);
    
    setRedoHistory([]); // Clear redo history
    setSchemaData(newSchema); // Update schema
  };
  
  
  const handleUndo = () => {
    if (undoHistory.length === 0) {
      chatbotResponse('No changes to undo.');
      return;
    }
  
    const lastSchema = undoHistory[undoHistory.length - 1];
    setRedoHistory((prev) => [...prev, schema_data]);
    setUndoHistory((prev) => prev.slice(0, -1));
    setSchemaData(lastSchema);
    chatbotResponse('Undid the last change.');
  };
  
  const handleRedo = () => {
    if (redoHistory.length === 0) {
      chatbotResponse('No changes to redo.');
      return;
    }
  
    const nextSchema = redoHistory[redoHistory.length - 1];
    setUndoHistory((prev) => [...prev, schema_data]);
    setRedoHistory((prev) => prev.slice(0, -1));
    setSchemaData(nextSchema);
    chatbotResponse('Redid the last undone change.');
  };
  
  

  const handleSignOut = () => {
    localStorage.removeItem('token'); // Clear token from localStorage
    navigate('/login'); // Redirect to login page
  };

  // Helper Functions
  const chatbotResponse = (message, feedback = null) => {
    if (!message && !feedback) return;
  
    // If the message is an object, convert it to a readable string
    if (typeof message === 'object') {
      message = <pre>{JSON.stringify(message, null, 2)}</pre>; // Format nicely for display
    }
  
    // If feedback is provided, render the DisplayFeedback component
    if (feedback) {
      setChatHistory((prev) => [
        ...prev,
        {
          from: 'bot',
          message: <DisplayFeedback feedback={feedback} />,
        },
      ]);
    } else {
      setChatHistory((prev) => [...prev, { from: 'bot', message }]);
    }
  };
  
  

  const userChatResponse = (message) => {
    if (!message.trim()) return;
    setChatHistory((prev) => [...prev, { from: 'user', message }]);
  };

  const showToast = (message) => {
    setToastMessage(message);
    setTimeout(() => setToastMessage(''), 3000);
  };

  // Step Navigation and Input Handlers
  const handleClassChange = (className) => {
    setSelectedClass(className);
    const cls = schema_data.classes.find(cls => cls.class_name === className);
    setProperties(cls ? cls.properties.map(prop => prop.property) : []);
    setSelectedProperty('');
  };

  const moveToNextCategory = () => {
    if (categoryIndex + 1 < categories.length) {
      setCategoryIndex((prev) => prev + 1);
      setStep(4); // Go back to Feedback Dashboard
    } else {
      chatbotResponse('All feedback categories processed.');
      setStep(4); // End at the feedback dashboard
    }
  };
  
  const detectJoinTables = (schema) => {
    console.log("Running join table detection...");
    
    // Filter for join tables that have more than one foreign key
    const detectedJoinTables = schema.classes.filter(cls =>
      cls.properties.filter(prop => prop.foreign_key).length > 1
    );
  
    console.log("Detected join tables:", detectedJoinTables);
    
    const newJoinTables = detectedJoinTables.filter(
      (cls) => !cls.promptedForChild || cls.isUpdated
    );
  
    if (newJoinTables.length > 0) {
      setJoinTablesToPrompt(newJoinTables);
      setCurrentJoinTable(newJoinTables[0].class_name);
      setJoinTableProperties(newJoinTables[0].properties.map(prop => prop.property));
      setJoinTablePromptActive(true);
      setIsProcessingJoinTable(true);
      setStep(15);
      chatbotResponse(`Detected a new join table: ${newJoinTables[0].class_name}. Please select child properties.`);
    } else {
      setIsProcessingJoinTable(false);
    }
  };
  
  const DisplayFeedback = ({ feedback }) => {
    if (!feedback) return null; // Handle cases where feedback might be null or undefined
  
    return (
      <div className="p-4 bg-gray-800 rounded-lg shadow-md text-white">
        <h3 className="text-lg font-bold text-[#68A9FF] mb-2">Feedback Details</h3>
        <div className="space-y-4">
          <div>
            <strong className="block text-green-400">Action:</strong>
            <p className="ml-4">{feedback.action || 'No action provided'}</p>
          </div>
          <div>
            <strong className="block text-yellow-400">Explanation:</strong>
            <p className="ml-4">{feedback.explanation || 'No explanation provided'}</p>
          </div>
        </div>
      </div>
    );
  };
  
  

  const handleChildSelection = (joinTable, selectedProperties) => {
    const updatedSchema = { ...schema_data };
  
    const joinTableClass = updatedSchema.classes.find(cls => cls.class_name === joinTable);
    joinTableClass.properties.forEach(prop => {
      prop.createChild = selectedProperties.includes(prop.property);
    });
  
    joinTableClass.promptedForChild = true;
    joinTableClass.isUpdated = false;
    setSchemaData(updatedSchema);
  
    chatbotResponse(`Updated ${joinTable} to create child objects for properties: ${selectedProperties.join(', ')}`);
  
    // Remove the current join table from the list
    setJoinTablesToPrompt(prevJoinTables => {
      const remainingJoinTables = prevJoinTables.filter(table => table.class_name !== joinTable);
  
      if (remainingJoinTables.length > 0) {
        // Set the next join table to process
        const nextJoinTable = remainingJoinTables[0];
        setCurrentJoinTable(nextJoinTable.class_name);
        setJoinTableProperties(nextJoinTable.properties.map(prop => prop.property));
        chatbotResponse(`Detected a new join table: ${nextJoinTable.class_name}. Please select child properties.`);
        // Keep processing join tables
      } else {
        // No more join tables to process
        setJoinTablePromptActive(false);
        setIsProcessingJoinTable(false);
  
        // Set step to 2 to trigger useEffect
        setStep(2);
      }
      return remainingJoinTables;
    });
  };
  
  
  const handleUserFeedbackResponse = async (apply) => {
    if (apply.toLowerCase() === 'yes') {
      if (categories[categoryIndex] === 'Relationships') {
        chatbotResponse('Please provide the source class and target class in the format: source_class,target_class');
        setStep(8);
      } else if (categories[categoryIndex] === 'Data Integrity') {
        chatbotResponse('Please proceed with data integrity modifications.');
        setStep(13);
      }
    } else {
      chatbotResponse('Skipping feedback...');
      moveToNextCategory();
    }
  };

  const handleInputChange = (value) => {
    setRelationshipInput(value);

    const classes = value.split(',').map(cls => cls.trim());
    const isSelectingTarget = classes.length > 1 && classes[0];

    if (!isSelectingTarget) {
      setSourceSuggestions(
        schema_data.classes.filter(cls => cls.class_name.toLowerCase().startsWith(classes[0].toLowerCase()))
      );
      setTargetSuggestions([]);
    } else {
      const targetStart = classes[1].toLowerCase();
      setTargetSuggestions(
        schema_data.classes.filter(cls => cls.class_name.toLowerCase().startsWith(targetStart))
      );
      setSourceSuggestions([]);
    }
  };

  const handleCreateChildResponse = (response) => {
    const normalizedResponse = response.trim().toLowerCase();
    if (normalizedResponse === 'yes' || normalizedResponse === 'no') {
      setCreateChildFlag(normalizedResponse === 'yes');
      chatbotResponse(`Create child objects: ${normalizedResponse === 'yes' ? 'Yes' : 'No'}`);
      
      // Update the schema based on user response
      if (normalizedResponse === 'yes') {
        markJoinTableAsChild();
      }
      
      moveToNextCategory();
    } else {
      chatbotResponse("Please type 'yes' or 'no'.");
    }
  };
  
  const markJoinTableAsChild = () => {
    // Assuming we have a join table named "OrganizationAddress"
    if (schema_data) {
      schema_data.classes.forEach((cls) => {
        if (cls.class_name === 'OrganizationAddress') {
          cls.createChild = true;
        }
      });
      setSchemaData({ ...schema_data });
      
      chatbotResponse('Marked join table as a child object.');
    }
  };
  
  
  // API Calls
  const fetchSuggestedClasses = async () => {
    try {
      const suggestedClasses = await fetchSuggestedClassesApi(businessType);
      setSuggestedClasses(suggestedClasses);

      const classList = suggestedClasses.map(cls => `- ${cls}`).join('\n');
      chatbotResponse(`AI Suggested Classes:\n${classList}`);
    } catch (error) {
      chatbotResponse('Error fetching suggested classes.');
    }
  };

  const fetchSuggestedProperties = async () => {
    try {
      const suggestedProperties = await fetchSuggestedPropertiesApi(className, schema_data, businessType);
      setSuggestedProperties(suggestedProperties);

      const propertyList = suggestedProperties.map((prop, index) => (
        <span
          key={index}
          className="cursor-pointer text-blue-400 hover:underline"
          onClick={() => addSuggestedProperty(prop)}
        >
          - {prop.property}: {prop.data_type}
        </span>
      ));
    } catch (error) {
      chatbotResponse('Error fetching suggested properties.');
    }
  };

  const handleBusinessSubmit = async () => {
    if (!businessType || !description) return;
    const userMessage = `Business Type: ${businessType}\nDescription: ${description}`;
    setChatHistory((prev) => [...prev, { from: 'user', message: userMessage }]);
    setChatHistory((prev) => [...prev, { from: 'bot', message: 'Generating schema based on your description...' }]);
  
    try {
      const generatedSchema = await generateSchemaApi(businessType, description);
      setSchemaData(generatedSchema);
      chatbotResponse("Here's the initial schema generated based on your description.");
      setStep(2); // Go to the schema review step
    } catch (error) {
      chatbotResponse('Error generating schema.');
    }
  };
  
  
  const handleFeedbackRequest = async () => {
    if (!isFeedbackTriggered || !schema_data) return;
  
    try {
      const categories = ['Redundancy', 'Relationships', 'Normalization', 'Data Integrity'];
      const feedbackResults = {};
  
      for (const category of categories) {
        const feedbackData = await getFeedbackApi(schema_data, category, businessType);
        feedbackResults[category.toLowerCase()] = feedbackData.feedback || [];
      }
  
      setAllFeedback(feedbackResults);
      setStep(4); // Ensure it navigates to the feedback dashboard
    } catch (error) {
      chatbotResponse('Error fetching feedback.');
    }
  };
  
  
  
  // Feedback Application and Integrity Management
  const applyRename = async () => {
    try {
      const updatedSchema = await renameEntityApi(schema_data, className, propertyName, newName);
      updateSchemaData(updatedSchema); // Track schema change
      showToast(`Renamed ${propertyName} to ${newName}`);
    } catch (error) {
      chatbotResponse(`Error renaming: ${error.response?.data?.detail || error.message}`);
    }
  };
  
  const applySetType = async () => {
    try {
      const updatedSchema = await setTypeApi(schema_data, className, propertyName, newType);
      updateSchemaData(updatedSchema); // Track schema change
      showToast(`New type: ${newType} set for ${propertyName} in class ${className}`);
    } catch (error) {
      chatbotResponse(`Error setting type: ${error.response?.data?.detail || error.message}`);
    }
  };
  

  const applyAddConstraint = async () => {
    try {
      const updatedSchema = await addConstraintApi(schema_data, className, propertyName, constraintType);
      setSchemaData(updatedSchema);
      updateSchemaData(updatedSchema); // Track schema change

      showToast(`Constraint ${constraintType} added to ${selectedClass}.${selectedProperty}.`);
    } catch (error) {
      chatbotResponse(`Error adding constraint: ${error.response?.data?.detail || error.message}`);
    }
  };

  const applyRemoveConstraint = async () => {
    try {
      const updatedSchema = await removeConstraintApi(schema_data, className, propertyName, constraintType);
      setSchemaData(updatedSchema);
      updateSchemaData(updatedSchema); // Track schema change

      showToast(`Constraint ${constraintType} removed from ${selectedClass}.${selectedProperty}.`);
    } catch (error) {
      chatbotResponse(`Error removing constraint: ${error.response?.data?.detail || error.message}`);
    }
  };

  const handleApplyManualRelationship = async (relationshipInput) => {
    // Validate input presence and type
    if (!relationshipInput || typeof relationshipInput !== 'string') {
      chatbotResponse('Error: Please provide a valid input in the format "source_class,target_class".');
      console.error('Invalid relationshipInput:', relationshipInput);
      return;
    }
  
    // Split and validate format
    const inputParts = relationshipInput.split(',');
    if (inputParts.length !== 2) {
      chatbotResponse('Error: Please provide both source and target classes separated by a comma.');
      console.error('Invalid input format:', inputParts);
      return;
    }
  
    // Trim and validate components
    const [source_class, target_class] = inputParts.map((part) => part.trim());
    if (!source_class || !target_class) {
      chatbotResponse('Error: Missing source or target class.');
      console.error('Parsed values - Source:', source_class, 'Target:', target_class);
      return;
    }
  
    try {
      // Apply relationship
      chatbotResponse(`Applying relationship between ${source_class} and ${target_class}...`);
      const updatedSchema = await applyRelationshipApi(schema_data, source_class, target_class);
      updateSchemaData(updatedSchema); // Track schema change
      setSchemaData(updatedSchema);
      chatbotResponse('Relationship successfully applied.');
  
      // Regenerate feedback for relationships
      chatbotResponse('Regenerating feedback for relationships...');
      const updatedFeedback = await getFeedbackApi(updatedSchema, 'Relationships', businessType);
  
      // Update relationship feedback in state
      const newFeedback = updatedFeedback.feedback || [];
      setAllFeedback((prevFeedback) => ({
        ...prevFeedback,
        relationships: newFeedback,
      }));
  
      // Feedback messages
      if (newFeedback.length === 0) {
        chatbotResponse('No issues detected for the relationships category.');
      } else {
        chatbotResponse('Updated relationship feedback detected.');
      }
    } catch (error) {
      // Handle errors
      console.error('Error applying relationship:', error);
      chatbotResponse('Error applying the relationship.');
    }
  };
  
  
  const handleClassNameSubmit = async () => {
    try {
      chatbotResponse('Applying redundancy feedback...');
      
      // Apply the redundancy feedback
      const updatedSchema = await applyRedundancyFeedbackApi(schema_data, feedback[0], newClassName);
      
      // Use updateSchemaData to track changes
      updateSchemaData(updatedSchema);
  
      chatbotResponse('Redundancy feedback applied successfully.');
  
      // Regenerate feedback for the current category
      chatbotResponse('Checking if feedback has been resolved...');
      const updatedFeedback = await getFeedbackApi(updatedSchema, 'Redundancy', businessType);
  
      // Update feedback for the redundancy category
      const newFeedback = updatedFeedback.feedback || [];
      setAllFeedback((prevFeedback) => ({
        ...prevFeedback,
        redundancy: newFeedback,
      }));
  
      if (newFeedback.length === 0) {
        chatbotResponse('No issues detected for the redundancy category.');
      } else {
        chatbotResponse('Updated redundancy feedback detected.');
      }
  
      // Navigate back to the feedback dashboard
      setStep(4);
    } catch (error) {
      chatbotResponse(`Error applying redundancy feedback: ${error.message}`);
    }
  };
  
  
  const handleNormalizationSelection = async () => {
    const selectedIndices = userResponse
      .split(',')
      .map(num => parseInt(num.trim(), 10) - 1)
      .filter(num => num >= 0 && num < normalizationFeedback.length);

    setSelectedNormalizationIndices(selectedIndices);
    chatbotResponse('Please provide new property names and data types for the selected normalization points.');
    setStep(9);
  };

  const applyNormalizationChanges = async () => {
    const entries = userResponse.split(',').map(entry => entry.trim());
    const newPropertiesArray = [];
    const dataTypesArray = [];
  
    entries.forEach(entry => {
      const [property, dataType] = entry.split(':').map(part => part.trim());
      if (property && dataType) {
        newPropertiesArray.push(property);
        dataTypesArray.push(dataType);
      }
    });
  
    if (!newPropertiesArray.length || !dataTypesArray.length) {
      chatbotResponse('Error: Please provide valid property names and data types.');
      return;
    }
  
    try {
      for (const idx of selectedNormalizationIndices) {
        const feedbackItem = normalizationFeedback[idx];
        const updatedSchema = await applyNormalizationApi(schema_data, feedbackItem, newPropertiesArray, dataTypesArray);
        updateSchemaData(updatedSchema); // Track schema change
        chatbotResponse('Normalization applied successfully.');
        moveToNextCategory();
      }
    } catch (error) {
      chatbotResponse('Error applying normalization feedback.');
    }
  };
  
  const handleSelectSuggestedClass = (className) => {
    const trimmedClassName = className.trim();
    setSelectedClass(trimmedClassName);
    setAdditionalClassName(trimmedClassName); // Automatically fill in the class name field
    fetchSuggestedProperties(trimmedClassName);
  };

  // Additional Class and Property Management
  const addClassToSchema = async () => {
    for (const property of newClassProperties) {
      if (!property.property || !property.data_type) {
        chatbotResponse('Error: Property name and data type cannot be empty.');
        return;
      }
    }
  
    try {
      const updatedSchema = await addClassToSchemaApi(schema_data, additionalClassName, newClassProperties);
      setSchemaData(updatedSchema);
      updateSchemaData(updatedSchema); // Track schema change

      setIsSchemaUpdated(true); // Mark schema as updated
      chatbotResponse(`Class '${additionalClassName}' added successfully.`);
      setStep(12);
    } catch (error) {
      chatbotResponse('Error adding class to schema.');
    }
  };
  

  const addPropertiesToClass = async () => {
    for (const property of newProperties) {
      if (!property.property || !property.data_type) {
        chatbotResponse('Error: Property name and data type cannot be empty.');
        return;
      }
    }
  
    try {
      const updatedSchema = await addPropertiesToClassApi(schema_data, existingClassName, newProperties);
      setSchemaData(updatedSchema);
      setIsSchemaUpdated(true); // Mark schema as updated
      updateSchemaData(updatedSchema); // Track schema change
      chatbotResponse(`Properties added to class '${existingClassName}' successfully.`);
      setStep(12);
    } catch (error) {
      chatbotResponse('Error adding properties to class.');
    }
  };
  

  const addSuggestedProperty = (prop) => {
    // Find the first empty property slot, if any
    const emptySlotIndex = newClassProperties.findIndex(
      (property) => !property.property && !property.data_type
    );
  
    if (emptySlotIndex !== -1) {
      // If there's an empty slot, fill it in
      setNewClassProperties((prev) => {
        const updatedProperties = [...prev];
        updatedProperties[emptySlotIndex] = {
          ...updatedProperties[emptySlotIndex],
          property: prop.property,
          data_type: prop.data_type,
          primary_key: prop.primary_key || false,
          foreign_key: prop.foreign_key || false,
          not_null: prop.not_null || false,
          references: prop.references || '',
        };
        return updatedProperties;
      });
    } else {
      // Otherwise, add a new property entry
      setNewClassProperties((prev) => [
        ...prev,
        {
          property: prop.property,
          data_type: prop.data_type,
          primary_key: prop.primary_key || false,
          foreign_key: prop.foreign_key || false,
          not_null: prop.not_null || false,
          references: prop.references || '',
        },
      ]);
    }
  };

  const handleAddClassProperty = () => {
    setNewClassProperties([...newClassProperties, { name: '', type: '' }]);
  };

  const handleClassPropertyChange = (index, field, value) => {
    const updatedProperties = [...newClassProperties];
    updatedProperties[index][field] = value;
    setNewClassProperties(updatedProperties);
  };

  const handleAddPropertyToClass = () => {
    setNewProperties([...newProperties, { name: '', type: '' }]);
  };

  const handlePropertyChange = (index, field, value) => {
    const updatedProperties = [...newProperties];
    updatedProperties[index][field] = value;
    setNewProperties(updatedProperties);
  };

  const handleRemoveClass = async (className) => {
    try {
      const updatedSchema = await removeClassApi(schema_data, className);
      setSchemaData(updatedSchema);
      updateSchemaData(updatedSchema); // Track schema change

      showToast(`Class '${className}' removed successfully.`);
    } catch (error) {
      chatbotResponse('Error removing class.');
    }
  };

  const handleRemoveProperty = async (className, propertyName) => {
    try {
      const updatedSchema = await removePropertyApi(schema_data, className, propertyName);
      setSchemaData(updatedSchema);
      updateSchemaData(updatedSchema); // Track schema change

      showToast(`Property '${propertyName}' removed from '${className}' successfully.`);
    } catch (error) {
      chatbotResponse('Error removing property.');
    }
  };

  const regenerateCategoryFeedback = async (category) => {
    try {
      chatbotResponse(`Checking if ${category} feedback has been resolved...`);
      const updatedFeedback = await getFeedbackApi(schema_data, category, businessType);
  
      const newFeedback = updatedFeedback.feedback || [];
      setAllFeedback((prevFeedback) => ({
        ...prevFeedback,
        [category.toLowerCase()]: newFeedback,
      }));
  
      if (newFeedback.length === 0) {
        chatbotResponse(`No issues detected for the ${category.toLowerCase()} category.`);
      } else {
        chatbotResponse(`Updated feedback detected for the ${category.toLowerCase()} category.`);
      }
    } catch (error) {
      chatbotResponse(`Error regenerating feedback for ${category}: ${error.message}`);
    }
  };

  const generateNoIssuesMessage = (category) => `No issues detected for the ${category} category.`;


  const regenerateFeedbackForCategory = async (category) => {
    try {
      chatbotResponse(`Checking if ${category} feedback has been resolved...`);
      const updatedFeedback = await getFeedbackApi(schema_data, category, businessType);
  
      const newFeedback = updatedFeedback.feedback || [];
      setAllFeedback((prevFeedback) => ({
        ...prevFeedback,
        [category.toLowerCase()]: newFeedback,
      }));
  
      if (newFeedback.length === 0) {
        const noIssuesMessage = generateNoIssuesMessage(category);
        chatbotResponse(noIssuesMessage);
      } else {
        chatbotResponse(`Updated feedback detected for the ${category} category.`);
      }
    } catch (error) {
      chatbotResponse(`Error regenerating feedback for ${category}: ${error.message}`);
    }
  };
  
  const handleFeedbackAction = async (category, index) => {
    const selectedFeedback = allFeedback[category][index];
  
    // Display feedback details in the chatbot
    chatbotResponse('Addressing feedback for the following point:', selectedFeedback);
  
    try {
      if (category === 'redundancy') {
        setFeedback([selectedFeedback]); // Store feedback
        setStep(5); // Navigate to ClassMerge
      } else if (category === 'relationships') {
        setFeedback([selectedFeedback]);
        setStep(8); // Navigate to Relationship Feedback
      } else if (category === 'normalization') {
        setFeedback([selectedFeedback]);
        setStep(7); // Navigate to Normalization Feedback
      } else if (category === 'dataIntegrity') {
        setFeedback([selectedFeedback]);
        setStep(13); // Navigate to Data Integrity Management
      } else {
        chatbotResponse(`Invalid feedback category: ${category}`);
      }
    } catch (error) {
      chatbotResponse(`Error handling feedback action: ${error.message}`);
    }
  };

  const handleRemoveConstraint = async (className, propertyName, constraintType) => {
    try {
      const updatedSchema = await removeConstraintApi(schema_data, className, propertyName, constraintType);
      setSchemaData(updatedSchema);
      updateSchemaData(updatedSchema); // Track schema change

      showToast(`Constraint '${constraintType}' removed from '${className}.${propertyName}' successfully.`);
    } catch (error) {
      chatbotResponse('Error removing constraint.');
    }
  };

// In the main component return structure
return (
<div className="flex h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
  {/* Main ChatBot Interface */}
  <div
    className={`flex flex-col transition-all duration-300 ${
      isCollapsed ? 'w-full' : 'w-full md:w-2/3'
    } h-full`}
  >
    {/* Navbar */}
    <nav className="p-4 flex justify-between items-center bg-gray-900 shadow-lg">
      <div className="flex items-center">
        <img src="./logo.png" alt="Logo" className="h-10 w-10 mr-2" />
        <h1 className="text-xl md:text-2xl font-bold text-blue-400">
          BizzNizz AI Builder
        </h1>
      </div>
      <div className="hidden md:flex space-x-4">
        <button
          onClick={handleUndo}
          className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-200"
        >
          Undo
        </button>
        <button
          onClick={handleRedo}
          className="px-4 py-2 bg-teal-500 text-white rounded-full hover:bg-teal-600 transition duration-200"
        >
          Redo
        </button>
        <Link
          to="/result"
          state={{ schema_data, businessType, description }} // Pass schema, business type, and description as state
        >
          <button           
            className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-full transition duration-200"
          >
            Finish
          </button>
        </Link>
        <button
          onClick={handleSignOut}
          className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-200"
        >
          Sign Out
        </button>
      </div>
    </nav>

    {/* Chat History Display */}
    <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 bg-gray-900">
      {chatHistory.map((chat, index) => (
        <div key={index} className={`mb-4 ${chat.from === 'bot' ? '' : 'text-right'}`}>
          <div
            className={`inline-block rounded-xl p-4 shadow-lg ${
              chat.from === 'bot' ? 'bg-[#4088FF]' : 'bg-gray-800'
            }`}
          >
            {typeof chat.message === 'string'
              ? chat.message.split('\n').map((line, lineIndex) => (
                  <span key={lineIndex}>
                    {line}
                    <br />
                  </span>
                ))
              : chat.message}
          </div>
        </div>
      ))}
    </div>

    {/* Dynamic Step-Based Interface */}
    <div className="p-4 bg-gray-800 shadow-lg rounded-t-lg overflow-y-hidden">
      {(() => {
          switch (step) {
            case 1:
              if (hasRefinedDescription) {
                return null; // Prevent rendering any unnecessary UI
              }
              return (
                <BusinessTypeAndDescription
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  description={description}
                  setDescription={setDescription}
                  filteredBusinessTypes={filteredBusinessTypes}
                  setFilteredBusinessTypes={setFilteredBusinessTypes}
                  businessTypesData={businessTypesData}
                  handleBusinessSubmit={handleBusinessSubmit}
                />
              );
            case 2: // Schema Review Step
            return (
              <div className="p-4 bg-gray-800 shadow-lg rounded-t-lg flex flex-col h-full max-h-[75vh] overflow-y-auto">
                <h2 className="text-xl mb-4 text-white">Generated Schema</h2>
                <div className="flex-1 overflow-y-auto">
                  <JSONPretty data={schema_data} />
                </div>
            
                <div className="mt-4 flex-shrink-0">
                  <button
                    className="w-full p-2 rounded-full transition bg-[#4088FF] text-white hover:bg-[#68A9FF] shadow-lg mb-4"
                    onClick={() => {
                      setIsFeedbackTriggered(false); // Ensure feedback isn't triggered on refine
                      setHasRefinedDescription(false); // Allow the user to revisit refinement
                      setStep(1); // Go back to description step
                    }}
                  >
                    Refine Description
                  </button>
                  <button
                    className="w-full p-2 rounded-full transition bg-green-600 text-white hover:bg-green-700 shadow-lg"
                    onClick={() => {
                      setIsFeedbackTriggered(true); // Trigger feedback processing
                      setHasRefinedDescription(true); // Mark description refinement as complete
                      setStep(3); // Proceed to feedback step
                    }}
                  >
                    Continue with Feedback
                  </button>
                </div>
              </div>
            );
            
            case 3: // Feedback Generation Step
              return (
                <div className="p-4 bg-gray-800 shadow-lg rounded-t-lg text-center">
                  <h2 className="text-xl text-[#68A9FF] mb-4">Generating Feedback...</h2>
                  <p className="text-white">Please wait while we analyze your schema and provide recommendations.</p>
                </div>
              );
            case 4: // Feedback Dashboard
              return (
                    <FeedbackDashboard
                      allFeedback={allFeedback}
                      handleFeedbackAction={handleFeedbackAction}
                      goToSchemaEditor={() => setStep(13)}
                      setStep={setStep}
                    />
              );
            case 5:
              return (
                <ClassMerge
                  newClassName={newClassName}
                  setNewClassName={setNewClassName}
                  handleClassNameSubmit={handleClassNameSubmit}
                />
              );
            case 6:
              return (
                <RelationshipFeedbackPrompt
                  userResponse={userResponse}
                  setUserResponse={setUserResponse}
                  handleUserFeedbackResponse={handleUserFeedbackResponse}
                />
              );
            case 7:
              return (
                <RelationshipConfirmation
                  userResponse={userResponse}
                  setUserResponse={setUserResponse}
                  handleUserFeedbackResponse={handleUserFeedbackResponse}
                />
              );
            case 8:
              return (
                <ApplyManualRelationship
                  relationshipInput={relationshipInput}
                  handleInputChange={handleInputChange}
                  sourceSuggestions={sourceSuggestions}
                  targetSuggestions={targetSuggestions}
                  setRelationshipInput={setRelationshipInput}
                  setSourceSuggestions={setSourceSuggestions}
                  setIsSelectingTarget={setIsSelectingTarget}
                  setTargetSuggestions={setTargetSuggestions}
                  handleApplyManualRelationship={handleApplyManualRelationship}
                  proceedToNextStep={moveToNextCategory}
                />
              );
            case 10:
              return (
                <ClassHandling
                  additionalClassName={additionalClassName}
                  setAdditionalClassName={setAdditionalClassName}
                  fetchSuggestedClasses={fetchSuggestedClasses}
                  suggestedClasses={suggestedClasses}
                  handleSelectSuggestedClass={handleSelectSuggestedClass}
                  suggestedProperties={suggestedProperties}
                  addSuggestedProperty={addSuggestedProperty}
                  newClassProperties={newClassProperties}
                  handleClassPropertyChange={handleClassPropertyChange}
                  setNewClassProperties={setNewClassProperties}
                  handleAddClassProperty={handleAddClassProperty}
                  addClassToSchema={addClassToSchema}
                  schema={schema_data}
                  handleRemoveClass={handleRemoveClass}
                  setStep={setStep}
                />
              );
            case 11:
              return (
                <PropertyHandling
                  existingClassName={existingClassName}
                  setExistingClassName={setExistingClassName}
                  schema={schema_data}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  setNewProperties={setNewProperties}
                  handleAddPropertyToClass={handleAddPropertyToClass}
                  addPropertiesToClass={addPropertiesToClass}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  setStep={setStep}
                  handleRemoveProperty={handleRemoveProperty}
                />
              );
            case 12:
              return (
                <SchemaReview
                  schema={schema_data}
                  chatbotResponse={chatbotResponse}
                  setStep={setStep}
                  businessType={businessType}
                  description={description}
                />
              );
            case 13:
              return (
                <DataIntegrityManagement
                  actionType={actionType}
                  setActionType={setActionType}
                  className={className}
                  setClassName={setClassName}
                  propertyName={propertyName}
                  setPropertyName={setPropertyName}
                  newName={newName}
                  setNewName={setNewName}
                  newType={newType}
                  setNewType={setNewType}
                  constraintType={constraintType}
                  setConstraintType={setConstraintType}
                  schema={schema_data}
                  applyRename={applyRename}
                  applySetType={applySetType}
                  applyAddConstraint={applyAddConstraint}
                  applyRemoveConstraint={applyRemoveConstraint}
                  setStep={setStep}
                />
              );
            case 15:
              return (
                <JoinTableChildPrompt
                  joinTable={currentJoinTable}
                  properties={joinTableProperties}
                  handleChildSelection={handleChildSelection}
                />
              );
              

            default:
              return <div>No Step Selected</div>;
            }
        })()}
      </div>
    </div>

{/* Live Schema Panel */}
<div
  className={`live-schema transition-all duration-300 ${
    isCollapsed ? 'w-0' : 'w-full md:w-1/3'
  } flex flex-col h-full`}
>
  {!isCollapsed && (
    <>
      {/* Header Section */}
      <div className="p-4 bg-gray-800 flex justify-between items-center">
        <h2 className="text-lg font-bold text-white">Live Schema</h2>
        <button
          onClick={() => setIsCollapsed(true)}
          className="p-2 bg-gray-700 hover:bg-gray-600 rounded"
        >
          Collapse
        </button>
      </div>

      {/* Schema Content Section */}
      <div className="flex-1 p-4 bg-gray-900 overflow-y-auto">
        {schema_data ? (
          <JSONPretty data={schema_data} />
        ) : (
          <p className="text-gray-400">No schema available</p>
        )}
      </div>
    </>
  )}

  {/* Collapse Button */}
  {isCollapsed && (
    <button
      onClick={() => setIsCollapsed(false)}
      className="absolute top-4 right-4 p-2 bg-gray-700 hover:bg-gray-600 rounded"
    >
      {'>'}
    </button>
  )}
</div>

</div>
);
};
export default ChatBot;
